
import './App.css';
import './assets/css/tailwind.css'
import './assets/css/materialdesignicons.min.css'
import { Route,Routes } from 'react-router-dom';
import IndexFour from './pages/index-four';
import AboutUs from './pages/aboutus';
import HostingShared from './pages/hosting-shared';
import HostingVps from './pages/hosting-vps';
import HostingDedicated from './pages/hosting-dedicated';
import HostingCloud from './pages/hosting-cloud';
import HostingDomain from './pages/hosting-domain';
import HostingReseller from './pages/hosting-reseller';
import Cart from './pages/cart';
import Checkouts from './pages/checkouts';
import Login from './pages/login';
import Signup from './pages/signup';
import ResetPassword from './pages/reset-password';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import Maintenance from './pages/maintenance';
import Error from './pages/error';
import ThankYou from './pages/thankyou';
import Contact from './pages/contact';
import Advnace from './pages/advance';

function App() {
  return (
    <Routes>
      <Route path='/' exact element={<IndexFour/>}/>
      <Route path='/aboutus' exact element={<AboutUs/>}/>
      <Route path='/hosting-shared' exact element={<HostingShared/>}/>
      <Route path='/hosting-vps' exact element={<HostingVps/>}/>
      <Route path='/hosting-dedicated' exact element={<HostingDedicated/>}/>
      <Route path='/hosting-cloud' exact element={<HostingCloud/>}/>
      <Route path='/hosting-domain' exact element={<HostingDomain/>}/>
      <Route path='/hosting-reseller' exact element={<HostingReseller/>}/>
      <Route path='/cart' exact element={<Cart/>}/>
      <Route path='/checkouts' exact element={<Checkouts/>}/>
      <Route path='/login' exact element={ <Login/>}/>
      <Route path='/signup' exact element={ <Signup/>}/>
      <Route path='/reset-password' exact element={ <ResetPassword/>}/>
      <Route path='/terms' exact element={<Terms/>}/>
      <Route path='/privacy' exact element={<Privacy/>}/>
      <Route path='/maintenance' exact element={<Maintenance/>}/>
      <Route path='/error' exact element={ <Error/>}/>
      <Route path='/thankyou' exact element={<ThankYou/>}/>
      <Route path='/contact' exact element={<Contact/>}/>
      <Route path='/advance' exact element={<Advnace/>}/>
    </Routes>
   
  );
}

export default App;
