import React,{useEffect, useState} from "react";
import { Link, useLocation } from 'react-router-dom'

import logoDark from '../assets/images/logo-dark.webp'
import logoLight from '../assets/images/logo-light.webp'
import logoWhite from '../assets/images/logo-white.png'

import {LuSearch, BiShoppingBag} from '../assets/icons/vander'

export default function Navbar({navClass,ulClass, navLight}){
    let [isOpen, setMenu] = useState(true);
    let [scroll, setScroll] = useState(false);
    let [country, setCountry] = useState(false);
    let [search, setSearch] = useState(false);
    let [cartitem, setCartitem] = useState(false);

    const [manu , setManu] = useState('');
    const location = useLocation();

    useEffect(()=>{
        var current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)
    },[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])

    useEffect(() => {
        function scrollHandler() {
            setScroll(window.scrollY > 50)
          }
        window.addEventListener('scroll', scrollHandler);
     
        let countryModal = () => {setCountry(false)}
        document.addEventListener('mousedown',countryModal);

        let searchModal = () => {setSearch(false)}
        document.addEventListener('mousedown',searchModal);

        let cartModal = () => {setCartitem(false)}
        document.addEventListener('mousedown',cartModal);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
            document.removeEventListener('mousedown',countryModal);
            document.removeEventListener('mousedown',searchModal);
            document.removeEventListener('mousedown',cartModal);
        };

    }, []);

    const toggleMenu = () => {
        setMenu(!isOpen)
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    }


  

    return(
        <nav id="topnav" className={ `${scroll ? 'nav-sticky' :''} ${navClass}`}>
            <div className="container relative flex justify-between">
                <Link className="logo" to="/">
                    {navLight ? 
                    <span className="inline-block dark:hidden">
                        <img src={logoDark} className="h-7 l-dark" alt=""/>
                        <img src={logoLight} className="h-7 l-light" alt=""/>
                    </span>  :
                    <span className="inline-block">
                        <img src={logoDark} className="h-7 inline-block" alt=""/>
                    </span>
                }
                    
                    <img src={logoWhite} className="h-7 hidden dark:inline-block" alt=""/>
                </Link>

                <div className="menu-extras">
                    <div className="menu-item">
                        <Link className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>
                <div id="navigation" className={`${isOpen === true ? 'hidden' : 'block'}`}>
                    <ul className={`${navClass ? `${ulClass}` :' navigation-menu justify-end '}  `}>
                        <li className={`${["/"].includes(manu)? "active" : ""}`}>
                            <Link to="/">Home</Link><span className="menu-arrow"></span>
                        </li>

                        <li className={manu === "aboutus" ? "active" : ""}><Link to="/aboutus" className="sub-menu-item">About Us</Link></li>

                        <li className={`${["hosting-shared","hosting-vps", "hosting-dedicated","hosting-cloud","hosting-domain","hosting-reseller"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="#"> Hosting </Link><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li className={manu === "hosting-shared" ? "active" : ""}><Link to="/hosting-shared" className="sub-menu-item">Shared Hosting</Link></li>
                                <li className={manu === "hosting-vps" ? "active" : ""}><Link to="/hosting-vps" className="sub-menu-item">VPS Hosting</Link></li>     
                                <li className={manu === "hosting-dedicated" ? "active" : ""}><Link to="/hosting-dedicated" className="sub-menu-item">Dedicated Server</Link></li> 
                                <li className={manu === "hosting-cloud" ? "active" : ""}><Link to="/hosting-cloud" className="sub-menu-item">Cloud Hosting</Link></li>
                                <li className={manu === "hosting-domain" ? "active" : ""}><Link to="/advance" className="sub-menu-item">Domain Name</Link></li>
                                <li className={manu === "hosting-reseller" ? "active" : ""}><Link to="/hosting-reseller" className="sub-menu-item">Reseller Hosting</Link></li>
                            </ul>  
                        </li>

                        <li className={`${["domain-detail","cart", "checkouts","my-account"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="#"> Domain </Link><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li className={manu === "cart" ? "active" : ""}><Link to="/cart" className="sub-menu-item">Cart</Link></li>
                                <li className={manu === "checkouts" ? "active" : ""}><Link to="/checkouts" className="sub-menu-item">Checkout</Link></li>
                            </ul>
                        </li>
                
                        <li className={`${["helpcenter-overview","helpcenter-faqs", "helpcenter-guides","helpcenter-support","blogs","blog-detail","login","signup","reset-password","lock-screen","terms","privacy","comingsoon","maintenance","error","thankyou"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                            <Link to="#">Pages</Link><span className="menu-arrow"></span>
                            <ul className="submenu">

                                <li className={`${["login","signup","reset-password","lock-screen"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="#"> Auth Pages </Link><span className="submenu-arrow"></span>
                                    <ul className="submenu">
                                        <li className={manu === "login" ? "active" : ""}><Link to="/login" className="sub-menu-item"> Login</Link></li>
                                        <li className={manu === "signup" ? "active" : ""}><Link to="/signup" className="sub-menu-item"> Signup</Link></li>
                                    </ul> 
                                </li>

                                <li className={`${["terms","privacy"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="#"> Utility </Link><span className="submenu-arrow"></span>
                                    <ul className="submenu">
                                        <li className={manu === "terms" ? "active" : ""}><Link to="/terms" className="sub-menu-item">Terms of Services</Link></li>
                                        <li className={manu === "privacy" ? "active" : ""}><Link to="/privacy" className="sub-menu-item">Privacy Policy</Link></li>
                                    </ul>  
                                </li>

                                <li className={`${["comingsoon","maintenance","error","thankyou"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="#"> Special </Link><span className="submenu-arrow"></span>
                                    <ul className="submenu">
                                        <li className={manu === "error" ? "active" : ""}><Link to="/error" className="sub-menu-item"> 404!</Link></li>
                                        <li className={manu === "thankyou" ? "active" : ""}><Link to="/thankyou" className="sub-menu-item"> Thank you</Link></li>
                                    </ul> 
                                </li>
                            </ul>
                        </li>
                
                        <li><Link to="/contact" className="sub-menu-item">Contact</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}