import React, { useState, useEffect } from "react";
import { Button } from "react-scroll";
import Tagline from "../components/tagline";
import Navbar from "../components/navbar";
import PricingTwo from "../components/pricingTwo";
import HostingServices from "../components/hostingServices";
import ServerOne from "../components/serverOne";
import ServerTwo from "../components/serverTwo";
import Faq from "../components/faq";
import Newsletter from "../components/newslatter";
import Footer from "../components/footer";
import { TypeAnimation } from "react-type-animation";
import { Link } from "react-router-dom";
import { IoSearch, IoSettings } from "react-icons/io5";

export default function IndexFour() {
  const [tldName, setTldName] = useState("");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 800);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 800);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <Tagline />
      <Navbar
        navClass="defaultscroll is-sticky tagline-height"
        ulClass="navigation-menu justify-end nav-light"
        navLight={true}
      />
      <section className="relative padding_banner bg-[url('../../assets/images/bg/bg1.jpg')] bg-center bg-cover">
        <div className="absolute inset-0 bg-gradient-to-b from-slate-950 to-transparent "></div>
        <div className="relative">
          <div className="items-center text-center mt-10">
            <h4 className="font-semibold lg:leading-normal leading-normal text-2xl lg:text-5xl text-white">
              Choose your Domain & Hosting <br /> Plan for
              <span
                className="typewrite text-yellow-500"
                data-period="2000"
                data-type='[ "Personal", "Business", "Starup", "Enterprise" ]'
              >
                {" "}
                <span className="wrap"></span>{" "}
              </span>
              <TypeAnimation
                sequence={[
                  "Personal",
                  2000,
                  "Business",
                  2000,
                  "Startup",
                  2000,
                  "Enterprise",
                  2000,
                ]}
                wrapper="span"
                speed={10}
                className="typewrite text-yellow-500"
                repeat={Infinity}
                cursor={false}
              />
            </h4>
            <p className="text-white/70 text-xl lg:text-lg max-w-xl mx-auto mb-3 banner_para">
              Create, collaborate, and turn your ideas into incredible products
              with the definitive platform for digital design.
            </p>

            <div style={{ width: "100%" }}>
              <form className="flex form_Domain">
                <div className="icon_search_domain">
                  <IoSearch />
                </div>
                <input
                  type="text"
                  onChange={(e) => setTldName(e.target.value)}
                  value={tldName}
                  className="search_domain"
                  placeholder="Enter your domain name"
                />
                <span className="advance_link">
                  <Link to="/advance">
                    {isLargeScreen ? "Advance" : <IoSettings />}
                  </Link>
                </span>
                <Button type="submit" className="domain_search_btn">
                  {isLargeScreen ? "Search" : <IoSearch />}
                </Button>
              </form>
            </div>
          </div>
          <div className="domain_list flex justify-between">
            <div
              className="border border-white px-6 py-2 rounded domain_name cursor-pointer"
              onClick={() => setTldName(".com")}
            >
              <h3 className="text-white text-xl">.com</h3>
              <span
                className="text-white decoration-overline text-xs	"
                style={{ textDecoration: "line-through" }}
              >
                Rs.3,999
              </span>
              <h3 className="text-white">Rs.1,699</h3>
            </div>
            <div
              className="border border-white px-6 py-2 rounded domain_name cursor-pointer"
              onClick={() => setTldName(".online")}
            >
              <h3 className="text-white text-xl">.online</h3>
              <span
                className="text-white decoration-overline text-xs	"
                style={{ textDecoration: "line-through" }}
              >
                Rs.10,090
              </span>
              <h3 className="text-white">Rs.590</h3>
            </div>
            <div
              className="border border-white px-6 py-2 rounded domain_name cursor-pointer"
              onClick={() => setTldName(".shop")}
            >
              <h3 className="text-white text-xl">.shop</h3>
              <span
                className="text-white decoration-overline text-xs	"
                style={{ textDecoration: "line-through" }}
              >
                Rs.10,050
              </span>
              <h3 className="text-white">Rs.290</h3>
            </div>
            <div
              className="border border-white px-6 py-2 rounded domain_name cursor-pointer"
              onClick={() => setTldName(".pro")}
            >
              <h3 className="text-white text-xl">.pro</h3>
              <span
                className="text-white decoration-overline text-xs	"
                style={{ textDecoration: "line-through" }}
              >
                Rs.7,178
              </span>
              <h3 className="text-white">Rs.890</h3>
            </div>
            <div
              className="border border-white px-6 py-2 rounded domain_name cursor-pointer"
              onClick={() => setTldName(".net")}
            >
              <h3 className="text-white text-xl">.net</h3>
              <span
                className="text-white decoration-overline text-xs	"
                style={{ textDecoration: "line-through" }}
              >
                Rs.4,593
              </span>
              <h3 className="text-white">Rs.2900</h3>
            </div>
            <div
              className="border border-white px-6 py-2 rounded domain_name cursor-pointer"
              onClick={() => setTldName(".pk")}
            >
              <h3 className="text-white text-xl">.pk</h3>
              <span
                className="text-white decoration-overline text-xs	"
                style={{ textDecoration: "line-through" }}
              >
                Rs.2,500
              </span>
              <h3 className="text-white">Rs.1,600</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:pb-24 pb-16 pricing_section">
        <PricingTwo priceClass="container relative md:mt-24 mt-16" />
        <HostingServices title="Hosting Services" />
        <ServerOne />
        <ServerTwo />
        <Faq />
        <Newsletter />
      </section>
      <Footer />
    </>
  );
}
