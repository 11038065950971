import React, { useState } from 'react';
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Tagline from "../components/tagline";
import { IoSearch } from "react-icons/io5";

export default function Advance() {
    const [tldName, setTldName] = useState(".com");
    const [selectedTlds, setSelectedTlds] = useState([]);
    const [showMore, setShowMore] = useState(false);

    const handleCheckboxChange = (tld) => {
        setSelectedTlds((prevSelected) => {
            if (prevSelected.includes(tld)) {
                return prevSelected.filter((item) => item !== tld);
            } else {
                return [...prevSelected, tld];
            }
        });
    };

    const displayValue = selectedTlds.length === 1 
        ? selectedTlds[0] 
        : selectedTlds.length > 1 
        ? `${selectedTlds.length}` 
        : tldName;

    const tlds = [
        ".com", ".net", ".org", ".info", ".xyz", ".ai", ".co", ".top", 
        ".shop", ".us", ".art", ".ac", ".io", ".vip", ".me", ".icu", 
        ".club", ".sh", ".blog", ".bio", ".mba", ".support", ".store", ".ventures", ".academy",
        ".zone", ".casino", ".media", ".recipes", ".credit",".international", ".marketing", ".news",
        ".show", ".technology", ".tools", ".capital", ".chat", ".church", ".exchange", ".expert", ".house", 
        ".money", ".photography", ".photos", ".pink", ".plus", ".poker", ".productions", ".red", ".tax", ".taxi", ".training", ".ltd",
        ".parts", ".coffee", ".dance", ".events", ".black", ".broker", ".shiksha", ".direct", ".singles", ".center", ".tips", ".sale",
        ".computer", ".careers", ".land", ".pics", ".luxury", ".bar", ".beauty", ".best", ".boats", ".bond", ".build", ".ceo", 
        ".cfd", ".christmas", ".college", ".desi", ".diet", ".cam", ".fans", ".feedback", ".flowers", ".forum", ".autos", ".baby",
        ".audio", ".makeup", ".motorcycles", ".systems", ".games", ".tickets", ".uno", ".website", ".rentals", ".pet", ".flights", ".movie",
        ".lotto", ".financial", ".claims", ".coach", ".ooo", ".press", ".qpon", ".quest", ".rent", ".sbs", ".skin", ".space",
        ".storage", ".tech", ".theatre", ".yachts", ".accountants", ".actor", ".agency", ".archi", ".army", ".associates", ".band", ".bargains",
        ".bet", ".boutique", ".directory", ".run", ".team", ".network", ".city", ".coupons", ".email", ".golf", ".services", ".wtf",
        ".doctor", ".engineering", ".global", ".investments", ".lgbt", ".loans", ".organic", ".partners", ".vote", ".voto", ".delivery", ".fitness",
        ".works", ".cafe", ".cheap", ".fun", ".game", ".guitars", ".hair", ".help", ".homes", ".host", ".hosting", ".lat",
        ".lol", ".mom", ".monster", ".forsale", ".fund", ".blue", ".care", ".cash", ".clinic", ".consulting", ".farm", ".finance",
        ".forex", ".haus", ".kitchen", ".ninja", ".pizza", ".promo", ".restaurant", ".shoes", ".ski", ".software", ".studio", ".style",
        ".toys", ".cool", ".dog", ".express", ".gold", ".green", ".irish", ".kim", ".markets", ".properties", ".repair", ".report",
        ".rocks", ".tours", ".town", ".vin", ".watch", ".wine", ".life", ".llc", ".digital", ".tv", ".mobi", ".world",
        ".cc", ".social", ".pro", ".live", ".biz", ".institute", ".solutions", ".cricket", ".trade", ".travel", ".faith", ".racing",
        ".review", ".party", ".men", ".bid", ".webcam", ".stream", ".science", ".win", ".date", ".loan", ".download", ".accountant",
        ".bike", ".tel", ".guru", ".estate", ".cyou", ".enterprises"
    ];

    const displayedTlds = showMore ? tlds : tlds.slice(0, 10);

    return (
        <>
            <Tagline />
            <Navbar navClass="defaultscroll is-sticky tagline-height" ulClass='navigation-menu justify-end' />
            <section className="relative table w-full md:pt-40 md:pb-56 pt-28 pb-48 bg-[url('../../assets/images/bg/bg5.png')] bg-center bg-cover">
                <div className="absolute inset-0 bg-sky-500/5"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-4">
                        <h3 className="text-4xl leading-normal font-semibold">Register Your Domain Name Here</h3>
                        <div className='adv_form_cont'>
                            <p className="text-slate-400 w-full banner_para">Enter your desired domain name and select the extensions you prefer to search for available options.</p>
                            <form className="flex form_Domain w-full mt-6">
                                <input 
                                    type="text" 
                                    onChange={(e) => setTldName(e.target.value)} 
                                    className="search_domain_advance px-4" 
                                    placeholder="Enter your domain name here" 
                                />
                                <span className='advance_link tld_slects_count'>{displayValue}</span>
                                <button className='domain_search_btn_advance flex justify-center align-middle text-3xl'><IoSearch /></button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative rounded tld_prize_adv">
                <div className="container relative z-2 -mt-36">
                    <h3 className="p-4 font-semibold text-gray-900 dark:text-white">Popular</h3>
                    <div className='flex flex-wrap w-full'>
                        {displayedTlds.map(tld => (
                            <div className="flex items-center mb-4 tlds_cont" key={tld}>
                                <input 
                                    id={tld} 
                                    type="checkbox" 
                                    checked={selectedTlds.includes(tld)} 
                                    onChange={() => handleCheckboxChange(tld)} 
                                    className="cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" 
                                />
                                <label htmlFor={tld} className="ms-6">{tld.slice(1)}</label>
                            </div>
                        ))}
                    </div>
                    {tlds.length > 10 && (
                        <button 
                            className="load_moretlds"
                            onClick={() => setShowMore(prev => !prev)}
                        >
                            {showMore ? "Show Less" : "Load More"}
                        </button>
                    )}
                </div>
            </section>
            <section className="tld_prices">
                <table>
                    <thead>
                        <tr>
                            <th>Domains</th>
                            <th>1 Year</th>
                            <th>2 Years</th>
                            <th>3 Years</th>
                            <th>5 Years</th>
                            <th>10 Years</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>.*.name</td>
                            <td>$ 10.88</td>
                            <td>$ 21.76</td>
                            <td>$ 32.64</td>
                            <td>$ 54.40</td>
                            <td>$ 108.80</td>
                        </tr>
                        <tr>
                            <td>.5g.in</td>
                            <td>$ 4.88</td>
                            <td>$ 14.76</td>
                            <td>$ 24.64</td>
                            <td>$ 44.40</td>
                            <td>$ 93.80</td>
                        </tr>
                        <tr>
                            <td>.6g.in</td>
                            <td>$ 4.88</td>
                            <td>$ 14.76</td>
                            <td>$ 24.64</td>
                            <td>$ 44.40</td>
                            <td>$ 93.80</td>
                        </tr>
                        <tr>
                            <td>.app</td>
                            <td>$ 21.88</td>
                            <td>$ 43.76</td>
                            <td>$ 65.64</td>
                            <td>$ 109.40</td>
                            <td>$ 218.80</td>
                        </tr>
                        <tr>
                            <td>.auto</td>
                            <td>$ 3,212.48</td>
                            <td>$ 6,424.96</td>
                            <td>$ 9,637.44</td>
                            <td>$ 16,062.40</td>
                            <td>$ 32,124.80</td>
                        </tr>
                        <tr>
                            <td>.bingo</td>
                            <td>$ 109.88</td>
                            <td>$ 219.76</td>
                            <td>$ 329.64</td>
                            <td>$ 549.40</td>
                            <td>$ 1,098.80</td>
                        </tr>
                        <tr>
                            <td>.car</td>
                            <td>$ 3,212.48</td>
                            <td>$ 6,424.96</td>
                            <td>$ 9,637.44</td>
                            <td>$ 16,062.40</td>
                            <td>$ 32,124.80</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <Footer />
        </>
    );
}
