import biz from '../assets/images/shop/biz.jpg'
import com from '../assets/images/shop/com.jpg'
import me from '../assets/images/shop/me.jpg'
import mobi from '../assets/images/shop/mobi.jpg'
import net from '../assets/images/shop/net.jpg'
import org from '../assets/images/shop/org.jpg'
import tv from '../assets/images/shop/tv.jpg'
import us from '../assets/images/shop/us.jpg'

import bizImg from '../assets/images/shop/biz.jpg'
import comimg from '../assets/images/shop/com.jpg'
import meImg from '../assets/images/shop/me.jpg'
import mobiImg from '../assets/images/shop/mobi.jpg'
import netImg from '../assets/images/shop/net.jpg'
import orgImg from '../assets/images/shop/org.jpg'
import tvImg from '../assets/images/shop/tv.jpg'
import usImg from '../assets/images/shop/us.jpg'

import {VscServer,TbCloudHeart,MdOutlineMarkEmailRead,LuUsers,BiDisc,LiaFileInvoiceDollarSolid,LiaWeightSolid,BiTachometer,LiaSwatchbookSolid,FiUserCheck,AiOutlineQuestionCircle,LuSettings} from '../assets/icons/vander'

export  const domainPlan = [
    {
        id:1,
        image:com,
        name:'.com',
        title:'Com',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:2,
        image:us,
        name:'.in',
        title:'In',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:3,
        image:org,
        name:'.online',
        title:'Online',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:4,
        image:net,
        name:'.net',
        title:'Net',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:5,
        image:com,
        name:'.co',
        title:'Com',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:6,
        image:biz,
        name:'.live',
        title:'Live',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:7,
        image:me,
        name:'.xyz',
        title:'Com',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:8,
        image:org,
        name:'.org',
        title:'Org',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:9,
        image:mobi,
        name:'.club',
        title:'Club',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:10,
        image:me,
        name:'.me',
        title:'Me',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:15,
        image:us,
        name:'.today',
        title:'Today',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:16,
        image:biz,
        name:'.life',
        title:'Life',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:17,
        image:tv,
        name:'.shop',
        title:'Shop',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:18,
        image:mobi,
        name:'.store',
        title:'Store',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },
    {
        id:19,
        image:org,
        name:'.solution',
        title:'Solution',
        registration: '$ 3.99',
        year:"$ 3.99",
        renew:"$ 3.99"
    },

]



export const serviceData = [
    {
        icon:VscServer,
        title:'Web Hosting',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:TbCloudHeart,
        title:'Domains',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:MdOutlineMarkEmailRead,
        title:'Emails',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:LuUsers,
        title:'Supported',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
   
]

export const counterData = [
    {
        start:'500',
        end:'1548',
        title:'Properties Sell'
    },
    {
        start:'0',
        end:'25',
        title:'Award Gained'
    },
    {
        start:'0',
        end:'9',
        title:'Years Experience'
    },
]
export const domainData = [
    {
        image:bizImg,
        amount:'3.99'
    },
    {
        image:comimg,
        amount:'3.99'
    },
    {
        image:meImg,
        amount:'3.99'
    },
    {
        image:mobiImg,
        amount:'3.99'
    },
    {
        image:netImg,
        amount:'3.99'
    },
    {
        image:orgImg,
        amount:'3.99'
    },
    {
        image:tvImg,
        amount:'3.99'
    },
    {
        image:usImg,
        amount:'3.99'
    },
]

export const serviceData2 = [
    {
        icon:VscServer,
        title:'Web Hosting',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:TbCloudHeart,
        title:'Domains',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:MdOutlineMarkEmailRead,
        title:'Emails',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:LuUsers,
        title:'Supported',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:BiTachometer,
        title:'Speedy',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:BiDisc,
        title:'Reliable',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon: LiaWeightSolid,
        title:'Scalable',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:LiaFileInvoiceDollarSolid,
        title:'Easy Upgrade',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
]

export const serverData = [
    {
        icon:LiaSwatchbookSolid,
        title:'Enhance Security',
        desc:'There are many variations of passages of Lorem Ipsum available'
    },
    {
        icon:BiTachometer,
        title:'High Performance',
        desc:'There are many variations of passages of Lorem Ipsum available'
    },
    {
        icon:FiUserCheck,
        title:'Unbeatable Support',
        desc:'There are many variations of passages of Lorem Ipsum available'
    },
]
export const accordionData = [
    {
        id:1,
        title:'How does it work ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:2,
        title:'Do I need a designer to use Hoxia ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:3,
        title:'What do I need to do to start selling ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:4,
        title:'What happens when I receive an order ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:5,
        title:'How does it work ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:6,
        title:'Do I need a designer to use Hoxia ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:7,
        title:'What do I need to do to start selling ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        id:8,
        title:'What happens when I receive an order ?',
        desc:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
]

export const feturesData = [
    {
        icon:AiOutlineQuestionCircle,
        title:'FAQs',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:LiaFileInvoiceDollarSolid,
        title:'Guides / Support',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:LuSettings,
        title:'Support Request',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
]
export  const FeatureData2 = [
    {
        icon:VscServer,
        title:'Web Hosting',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:TbCloudHeart,
        title:'Domains',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:MdOutlineMarkEmailRead,
        title:'Emails',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:LuUsers,
        title:'Supported',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:BiTachometer,
        title:'Speedy',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        icon:BiDisc,
        title:'Reliable',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
   
]

export const priceData=[
    {
        titit:'Starter',
        price:'4.99',
        features:["We offers a free month of service for new customers.","10GB Ram","100GB Bandwith","100GB SSD Storage","3 Domain Hosted Support","50 Email Account","5 Database","Unlimited Traffic"]
    },
    {
        titit:'Business',
        price:'14.99',
        features:[" We offers a free 7 days of service for new customers.","30GB Ram","500GB Bandwith","500GB SSD Storage","10 Domain Hosted Support","100 Email Account","10 Database","Unlimited Traffic"]
    },
    {
        titit:'Enterprise',
        price:'49.99',
        features:["We offers a free 14 days of service for new customers.","100GB Ram","1000GB Bandwith","1000GB SSD Storage","Unlimited Domain Hosted Support","Unlimited Email Account","Unlimited Database","Unlimited Traffic"]
    },

]

export const  serverPlanOne = [
    {
        id:1,
        plans:'Basic',
        processor:'Intel E3',
        ram:'4 GB DDR3',
        disk:'1000 GB HDD',
        bandwidth:'5 TB',
        price:'$ 5.99/month'
    },
    {
        id:2,
        plans:'Standard',
        processor:'Intel E3',
        ram:'4 GB DDR3',
        disk:'1000 GB HDD',
        bandwidth:'5 TB',
        price:'$ 5.99/month'
    },
    {
        id:3,
        plans:'Elite',
        processor:'Intel E3',
        ram:'8 GB DDR3',
        disk:'1000 GB HDD',
        bandwidth:'5 TB',
        price:'$ 5.99/month'
    },
    {
        id:4,
        plans:'Professional',
        processor:'Intel E3',
        ram:'16 GB DDR3',
        disk:'1000 GB HDD',
        bandwidth:'10 TB',
        price:'$ 5.99/month'
    },
    {
        id:5,
        plans:'Advanced',
        processor:'Intel Xeon D',
        ram:'16 GB DDR4',
        disk:'1000 GB HDD',
        bandwidth:'15 TB',
        price:'$ 5.99/month'
    },
    {
        id:6,
        plans:'Basic',
        title:'ST',
        color:'text-sky-500',
        processor:'Intel E3',
        ram:'28 GB DDR4',
        disk:'1000 GB HDD',
        bandwidth:'10 TB',
        price:'$ 5.99/month'
    },
    {
        id:7,
        plans:'Advanced',
        title:'ELITE',
        color:'text-green-600',
        processor:'Intel E3',
        ram:'28 GB DDR4',
        disk:'1000 GB HDD',
        bandwidth:'10 TB',
        price:'$ 5.99/month'
    },
    {
        id:8,
        plans:'Advanced',
        title:'BUSINESS',
        color:'text-yellow-500',
        processor:'Intel E3',
        ram:'60 GB DDR4',
        disk:'1000 GB HDD',
        bandwidth:'15 TB',
        price:'$ 5.99/month'
    },
    {
        id:9,
        plans:'Advanced',
        title:'PRO',
        color:'text-purple-600',
        processor:'Intel E3',
        ram:'60 GB DDR4',
        disk:'1000 GB HDD',
        bandwidth:'15 TB',
        price:'$ 5.99/month'
    }
]


export const orderData = [
    {
        orderNo:'7107',
        date:'13th March 2023',
        status:'Delivered',
        item:'for 2items',
        price:'$ 320'
    },
    {
        orderNo:'8007',
        date:'6th May 2023',
        status:'Processing',
        item:'for 1items',
        price:'	$ 800'
    },
    {
        orderNo:'8008',
        date:'19th June 2023',
        status:'Canceled',
        item:'for 1items',
        price:'$ 800'
    },
]
export const domainNames =[
    {
        id:"1",
        domain_name:".com",
        old_price:"Rs.3,999",
        new_price:"Rs.1,699"
    },
    {
        id:"2",
        domain_name:".online",
        old_price:"Rs.10,090",
        new_price:"Rs.590"
    },
    {
        id:"3",
        domain_name:".shop",
        old_price:"Rs.10,050",
        new_price:"Rs.290"
    },
    {
        id:"4",
        domain_name:".pro",
        old_price:"Rs.7,178",
        new_price:"Rs.890"
    },
    {
        id:"5",
        domain_name:".net",
        old_price:"Rs.4,593",
        new_price:"Rs.2900"
    },
    {
        id:"6",
        domain_name:".pk",
        old_price:"Rs.2500",
        new_price:"Rs.1600"
    }
]
export const monthlyPricing =[
    {
        id:"1",
        type:"Shared",
        price:"499",
        offer1:"NVME Disk Space",
        offer2:"cPanel Access",
        offer3:"Good Speed",
        offer4:"Free SSL",
        offer5:"1-Click Installer",
        offer6:"Standard Support",
        offer7:"Daily Backup"
    },
    {
        id:"2",
        type:"Reseller",
        price:"999",
        offer1:"NVME Disk Space",
        offer2:"WHM Access",
        offer3:"Best Speed",
        offer4:"Free SSL",
        offer5:"1-Click Installer",
        offer6:"Dedicated Support",
        offer7:"Daily Backup"
    },
    {
        id:"3",
        type:"Licenses",
        price:"350",
        offer1:"100% Safe & Secure",
        offer2:"Cheapest Guaranteed",
        offer3:"Unlimited IP Changes",
        offer4:"Standard Support",
        offer5:"Official Updates",
        offer6:"Instant Activation",
        offer7:"Yearly Discount"
    }
]
export const yearlyPricing=[
    {
        id:"1",
        type:"Shared",
        price:"499",
        offer1:"NVME Disk Space",
        offer2:"cPanel Access",
        offer3:"Good Speed",
        offer4:"Free SSL",
        offer5:"1-Click Installer",
        offer6:"Standard Support",
        offer7:"Daily Backup"
    },
    {
        id:"2",
        type:"Reseller",
        price:"999",
        offer1:"NVME Disk Space",
        offer2:"WHM Access",
        offer3:"Best Speed",
        offer4:"Free SSL",
        offer5:"1-Click Installer",
        offer6:"Dedicated Support",
        offer7:"Daily Backup"
    },
    {
        id:"3",
        type:"Licenses",
        price:"350",
        offer1:"100% Safe & Secure",
        offer2:"Cheapest Guaranteed",
        offer3:"Unlimited IP Changes",
        offer4:"Standard Support",
        offer5:"Official Updates",
        offer6:"Instant Activation",
        offer7:"Yearly Discount"
    }
]